<template>
    <div class="box">
      <div class="tishi">“页面完善中”</div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style lang="scss" scoped>
  .tishi{
    font-size: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,50%);
  }
  </style>